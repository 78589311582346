import React from 'react'

export default function PlayerScores(props) {
    if(!props.match.completed_at) return null
    let scores = <>{props.match.player.score} - {props.match.opponent.score}</>
    if(props.match.isParticipant) {
        let won = props.match.player.isYou && props.match.player_score > props.match.opponent_score
        scores = <span style={{ color: (won?'green':'red'), fontWeight: 'bold' }}>{scores}</span>
    }
    if(props.match.recording_url) {
//        let buttonImage = 'playback.jpg'
        if(props.match.recording_url.match(/^https?:\/\/twitch.tv\//)) {
//            buttonImage = 'twitch.jpg'
        }
        if(props.match.recording_url.match(/^https?:\/\/(youtube\.com|youtu\.be)\//)) {
//            buttonImage = 'youtube.jpg'
        }
        scores = <a
            href={props.match.recording_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'none' }}
        >{scores}&nbsp;<span role="img" aria-label="play">▶️</span></a>
    }
    return scores
}

