import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Table, Button, ButtonGroup, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import { Datepicker, SubmitBtn } from "react-formik-ui";
import axios from 'axios';

import api from './apiClient';

import PlayerNames from './PlayerNames'
import PlayerScores from './PlayerScores'
import MatchSchedule from './MatchSchedule'
import XWingList from './XWingList'

// TODO: include twitch-link, youtube-link, room-names, if any
function MatchPlatform(props) {
    let match = props.match
    return match.platform
}

function ScheduleModal(props) {

    const onSubmit = async (values) => {
        await api.setMatchSchedule(props.match.id,values.scheduled_at);
        if (props.onSubmit) props.onSubmit()
        props.onHide()
    }

    return(
    <Modal show={props.show} onHide={props.onHide} variant="dark" animation={false}>
        <Formik
            initialValues={{ "scheduled_at": props.match.scheduled_at }}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <>
                <Modal.Header closeButton>
                    <Modal.Title>Scheduled date &amp; time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Datepicker
                            name="scheduled_at"
                            showTimeSelect
                            timeFormat="H:mm"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd H:mm"
                            inline
                            todayButton="Today"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <SubmitBtn>Save</SubmitBtn>
                </Modal.Footer>
                </>
            )}
            </Formik>
    </Modal>)
}

function ResultMiniForm(props) {
 
    async function saveResult(values) {
        await axios.post(`/api/match/${props.match.id}`, values)
        // TODO: handle errors maybe?
        if(props.onSubmit) props.onSubmit()
    }

    return(
        <Formik
            onSubmit={saveResult}
            initialValues={{player_score:"", opponent_score:""}}
        >
            <Form>
                <Field name="player_score" placeholder={props.isParticipant ? "you" : props.match.player.display_name} style={{ width: "4em" }} pattern="[0-9]*" />
                &nbsp;-&nbsp;
                <Field name="opponent_score" placeholder={props.isParticipant ? "them" : props.match.opponent.display_name} style={{ width: "4em" }} pattern="[0-9]*" />
                &nbsp;
                <Button variant="outline-light" type="submit">Submit</Button>
            </Form>
        </Formik>
    )
}

// TODO: tidier display if player is a participant
function MatchDetails(props) {
    let player = props.player
    let match = props.match
    let isParticipant = player && [match.player.id, match.opponent.id].includes(player.id)
    let isOrganizer = player && match.tournament && match.tournament.organizerIds.includes(player.id)
    let mayEdit = isParticipant || isOrganizer
    let history = useHistory()
    return (
        <Table bordered variant="dark">
            <tbody>
                <tr>
                    <th>Players</th>
                    <td><PlayerNames player={player} match={match} /></td>
                </tr>
                {match.completed_at &&                              // done & locked in
                    <tr>
                        <th>Played</th>
                        <td><MatchSchedule match={match} /></td>
                    </tr>
                }
                {match.started_at && !match.completed_at &&         // currently running match
                    <tr>
                        <th>Started at</th>
                        <td>
                            <MatchSchedule match={match} />
                            {isParticipant && player.id===1 && <Button variant="outline-light">End</Button>}
                        </td>
                    </tr>
                }
                {!match.started_at && !match.completed_at && mayEdit &&    // future & participant
                    <tr>
                        <th>Schedule</th>
                        <td>
                            <MatchSchedule match={match} />
                            &nbsp;
                            <Button variant="outline-light" onClick={props.showScheduler}>{match.scheduled_at ? 'Reschedule' : 'Schedule'}</Button>
                            &nbsp;
                            <Button variant="outline-light" onClick={props.startMatch}>Start</Button>
                        </td>
                    </tr>
                }
                {!match.started_at && !match.completed_at && !mayEdit &&    // future other people
                    <tr>
                        <th>Schedule</th>
                        <td>
                            <MatchSchedule match={match} />
                        </td>
                    </tr>
                }
                <tr>
                    <th>Platform</th>
                    <td>
                        {mayEdit ?
                            <ButtonGroup>
                                <Button variant="outline-light">Real-Life</Button>
                                <Button variant="outline-light">Vassal</Button>
                                <Button variant="outline-light">TTS</Button>
                            </ButtonGroup>
                            : <MatchPlatform match={match} />
                        }
                    </td>
                </tr>
                {!match.completed_at && mayEdit &&
                    <tr>
                        <th>Result</th>
                        <td><ResultMiniForm match={match} isParticipant={isParticipant} onSubmit={props.onSubmit}/></td>
                    </tr>
                }
                {match.completed_at &&
                    <tr>
                        <th>Result</th>
                        <td>
                            <PlayerScores match={match} player={player} />
                        </td>
                    </tr>
                }
                {(isOrganizer || isParticipant) &&
                    <tr>
                        <th>Recording Link</th>
                        <td>
                            <a href={match.recording_url} target="_blank" rel="noopener noreferrer">{match.recording_url}</a>
                        </td>
                    </tr>
                }
                {isParticipant &&
                    <tr>
                        <th>List</th>
                        <td>
                            <XWingList list={player.id === match.player.id ? match.player.list : match.opponent.list} />
                            &nbsp;
                            <Button
                                variant="outline-light"
                                onClick={() => history.push(`/match/${match.id}/list/${player.id}`)}
                            >Edit</Button>
                        </td>
                    </tr>
                }
                {isOrganizer && !isParticipant &&           // non-playing TO can edit lists
                    <tr>
                        <th>List {match.player.display_name}</th>
                        <td>
                            <XWingList list={match.player.list} />
                            &nbsp;
                            <Button
                                variant="outline-light"
                                onClick={() => history.push(`/match/${match.id}/list/${match.player.id}`)}
                            >Edit</Button>
                        </td>
                    </tr>
                }
                {isOrganizer && !isParticipant &&           // non-playing TO can edit lists
                    <tr>
                        <th>List {match.opponent.display_name}</th>
                        <td>
                            <XWingList list={match.opponent.list} />
                                &nbsp;
                                <Button
                                variant="outline-light"
                                onClick={() => history.push(`/match/${match.id}/list/${match.opponent.id}`)}
                            >Edit</Button>
                        </td>
                    </tr>
                }
                {isOrganizer && match.announceUrl &&
                    <tr>
                        <th>Announce URL</th>
                        <td><a href={match.announceUrl} target="_blank" rel="noopener noreferrer">link</a></td>
                    </tr>
                }
            </tbody>
        </Table>
    );
}

export default function Match(props) {
    
    const [showScheduler, setShowScheduler] = useState(false)
    const handleCloseScheduler = () => setShowScheduler(false)
    const handleShowScheduler = () => setShowScheduler(true)

    let { id } = useParams();

    const [isChanged, setIsChanged] = useState(false)

    // State and fetcher for the match
    const [match, setMatch] = useState(null);
    useEffect(() => {
        async function fetchMatch(id) {
            if (!id) return;
            const response = await axios(`/api/match/${id}`)
            setMatch(response.data);
            setIsChanged(false)
        }
        fetchMatch(id || props.id);
    }, [id, props.id, isChanged]);

    if (!id && !props.id) { return (
        <>
            <h3>What Match?</h3>
            <p>This page should intelligently decide what match to show.</p>
            <p>If there is a logged in player, it should be, in order of priority:</p>
            <ol>
                <li>Their current ongoing match</li>
                <li>A match they completed today</li>
                <li>Their first upcoming match</li>
                <li>Their last match</li>
            </ol>
            <p>If there is no player logged in, it should maybe just show the last started on-going match,
                or the first upcoming one.
            </p>
        </>
    )}
    if (!match) return null

    let isParticipant = props.player && [match.player.id, match.opponent.id].includes(props.player.id)
    let isOrganizer = props.player && match.tournament && match.tournament.organizerIds.includes(props.player.id)
    let mayEdit = isParticipant || isOrganizer
    // load identified match, or otherwise upcoming match for player
    // if isParticipant then allow editing of schedule/started/completed/score
    // if player = participant then allow editing and viewing of THEIR xws
    // if match is completed allow viewing of XWS
    return (
        <>
            <MatchDetails
                match={match}
                player={props.player}
                showScheduler={handleShowScheduler}
                onSubmit={() =>  setIsChanged(true)}
            />
            {mayEdit && <ScheduleModal show={showScheduler} onHide={handleCloseScheduler} onSubmit={()=> setIsChanged(true)} match={match} />}
 
        </>
    )
}