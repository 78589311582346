import React from 'react'
import moment from 'moment'

function TimeRange(props) {
    let from = moment(props.from)
    let to = moment(props.to)
    if (from.hour()) return from.format("MMM Do, H:mm") + '-' + to.format("H:mm")
    if (from.date() === to.date()) return from.format("MMM Do")
    if (from.month() === to.month()) return from.format("MMM Do") + '-' + to.format("Do")
    return from.format("MMM Do") + ' - ' + to.format("MMM Do")
}

export default function MatchSchedule(props) {
    let match = props.match
    if (match.completed_at && match.started_at) return <TimeRange from={match.started_at} to={match.completed_at} />
    if (match.completed_at) return moment(match.completed_at).format("MMM Do, H:mm")
    if (match.started_at) return moment(match.started_at).format("MMM Do, H:mm")
    if (match.scheduled_at) return moment(match.scheduled_at).format("MMM Do, H:mm")
    if (!match.round_schedule) return null
    if (match.round_schedule.end) return <TimeRange from={match.round_schedule.start} to={match.round_schedule.end} />
    return moment(match.round_schedule.start).format("MMM Do, H:mm")
}