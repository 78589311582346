import React from 'react'

function ShipIcon(props) {
    let ship = props.ship
    if(ship === 'tieininterceptor') ship = 'tieinterceptor' 
    return <i className={`xwing-miniatures-ship xwing-miniatures-ship-${ship}`} />
}

export default function XWingList(props) {
    if(!props.list || !(props.list.ships || props.list.xws)) return null
    let ships = props.list.ships || props.list.xws.pilots.map( pilot => pilot.ship )    // either pregen shiplist, or gen-in-place
    let list = <>{ships.map( (ship, index) => <ShipIcon key={index} ship={ship} />)}</>
    if(props.list.isPrivate) list = <span style={{opacity:0.5}}>({list})</span>   // between parens if private
    if(props.list.yasb) {   // wrap in link of we have a YASB link
        list = <a href={props.list.yasb} target="_blank" rel="noopener noreferrer" style={{color: 'inherit', textDecoration: 'none'}}>{list}</a>
    }
    return list
}
