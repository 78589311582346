import axios from 'axios';

// TODO: I guess define the API base-url and make everything relative to that

const Api = {}

export default Api;

const client = axios.create({
    baseURL: '/api'
})

Api.setMatchSchedule = async function(matchId, scheduledDate) {
    try {
        await client.post(`/match/${matchId}`, { scheduled_at: scheduledDate} );
    } catch(error) {
        console.log(error);
    }
    return;
}

Api.startMatch = async function (matchId) {
    try {
        await client.post(`/match/${matchId}/start`);
    } catch (error) {
        console.log(error);
    }
    return;
}
