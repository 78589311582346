import React from 'react';

import Schedule from './Schedule';

export default function Dashboard(props) {
    let player = props.player
    // get upcoming match
    return(
      <>
        <Schedule player={player} />

        <h3>Dashboard</h3>
        <p>This page should show the most relevant information for the currently logged in player.</p>
        <ul>
          <li>Any results that need confirming (if any)</li>
          <li>Standing in current tournament</li>
          <li>Current match (if any)</li>
          <li>Upcoming match</li>
          <li>Last match</li>
        </ul>
        <p>For not logged in players, it should probably show a list of current, upcoming and past tournaments</p>
      </>
    )
  }
  