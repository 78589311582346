import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import GoogleLogin from 'react-google-login';

import axios from 'axios';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'xwing-miniatures-font-2-0/dist/xwing-miniatures.css';
import 'xwing-miniatures-font/dist/xwing-miniatures.css';

import Match from "./Match"
import Standings from "./Standings"
import Dashboard from "./Dashboard"
import History from "./History"
import Profile from "./Profile"
import Login from "./Login"
import Logout from "./Logout"
import Schedule from "./Schedule"
import ListForm from './ListForm'

// Helper for unknown URLs and under construction
function NoMatch() { return(<h3>Not Found</h3>) }

const LOGIN_TOKEN_COOKIE = "Astromech-Login-Token";
const GOOGLE_CLIENT_ID = "172226794806-0gss058e8csi0ifnesftl56von8i11o8.apps.googleusercontent.com"

function GoogleLoginButton() {
  const [, setCookie] = useCookies([LOGIN_TOKEN_COOKIE]);

  async function processLoginResponse(loginResponse) {
    console.log("Google login response:")
    console.log(loginResponse);
    const tokenId = loginResponse.tokenId  // TODO: check this is the right field
    console.log('Sending id-token to backend for verification')
    const verifyResponse = await axios.post(`/api/login/google`,{tokenId: tokenId});
    if(verifyResponse.status !== 200) {
      console.log("Google verify response not a success:", verifyResponse.status)
      console.log(verifyResponse)
      return;
    }
    console.log("We got and verified a valid Google Login token")
    const loginToken = verifyResponse.data.loginToken
    if(!loginToken) {
      console.log("No login token in 200-response?")
      return
    }
    console.log("We found a valid user for this Google Login")
    // TODO: instead of the below, call a function shared with Login.js
    setCookie(LOGIN_TOKEN_COOKIE, loginToken, { path: '/', maxAge: 30*24*60*60 });
    // TODO: invoke /api/profile to confirm it's a valid token, and logout if not
    // return(<Redirect to="/" />)
    window.location.reload()
  }


  return(
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={processLoginResponse}
      onFailure={processLoginResponse}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
  />  
  )
}

// Main App which is just a router
export default function App() {

  // TODO: have the app look up default player-group based on domain-name
  // which would then limit dashboard/standing/etc stuff to tournaments/players in that player-group

  // State and effect for fetching the current player
  const [player, setPlayer] = useState(null);
  useEffect(() => {
    async function fetchPlayer() {
      const response = await axios(`/api/profile`);
      if (response.status === 200) setPlayer(response.data)
    }
    fetchPlayer();
  }, []);

  return (
    <Router>
      <>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Brand as={Link} to="/">
            <i className="xwing-miniatures-font xwing-miniatures-font-astromech"></i>
            &nbsp;Astromech
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/">{player ? 'Dashboard' : 'Standings'}</Nav.Link>
              {player && <Nav.Link as={Link} to="/standings">Standings</Nav.Link>}
              <Nav.Link as={Link} to="/schedule">Schedule</Nav.Link>
              <Nav.Link as={Link} to="/history">History</Nav.Link>
              {player && <Nav.Link as={Link} to="/profile">Profile</Nav.Link>}
              {player && <Nav.Link as={Link} to="/logout">Logout</Nav.Link>}
              {!player && <GoogleLoginButton />}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <>
            <Switch>
                <Route path="/" exact>
                  {player ? <Dashboard player={player} /> : <Standings />}
                </Route>
                <Route path="/match/:match_id/list/:player_id">
                  <ListForm />
                </Route>
                <Route path="/player/:playerId/history">
                  <History />
                </Route>
                <Route path="/match/:id">
                  <Match player={player} />
                </Route>
                <Route path="/standings">
                  <Standings player={player} />
                </Route>
                <Route path="/schedule">
                    <Schedule />
                </Route>
                <Route path="/history">
                  <History player={player} />
                </Route>
                <Route path="/profile">
                  <Profile player={player} />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/logout">
                  <Logout />
                </Route>
                <Route path="*">
                  <NoMatch />
                </Route>
            </Switch>
          </>
      </>
    </Router>
  );
}


