import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import axios from 'axios';

import XWingList from './XWingList'

// Schedule for all players
// Need to figure out what variables
export default function History(props) {

    let { playerId } = useParams();

    const [history, setHistory] = useState([]);
    const [player, setPlayer] = useState(null)

    useEffect(() => {
      async function fetchHistory(playerId) {
        const url = props.tournamentId
                      ? `/api/tournament/${props.tournamentId}/player/${playerId}/history`
                      : `/api/player/${playerId}/history`
        const response = await axios(url)
        setHistory(response.data);
      }
      async function fetchPlayer(playerId) {
        const response = await axios(`/api/player/${playerId}`)
        setPlayer(response.data)
      }
      if (props.player) setPlayer(props.player)
      if (playerId) fetchPlayer(playerId)
      fetchHistory(props.player ? props.player.id : playerId)
    }, [props.player, props.tournamentId, playerId]);
  
    if(!player) return null;

    return (
      <>
        <Table striped bordered hover variant="dark">
          <thead>
            {!player.isYou &&
              <tr><td colSpan="100">
                History for {player.public_name || player.display_name}
              </td></tr>
            }
            <tr>
              {props.tournamentId && <td>Round</td>}
              <td>Opponent</td>
              <td>List</td>
              <td>Score</td>
              <td>MoV</td>
            </tr>
          </thead>
          <tbody>
            {history.map(match => 
              <tr key={match.id}>
                {props.tournamentId && <td>{match.round}</td>}
                <td>{match.opponent.display_name} <XWingList list={match.opponent.list} /></td>
                <td><XWingList list={match.player.list} /></td>
                {match.completed_at ? <td style={{color: match.player_points ? 'green' : 'red'}}>{match.player.score} - {match.opponent.score}</td> : <td>-</td> }
                <td>{match.completed_at ? match.player.mov : null}</td>
              </tr>
            )}
          </tbody>
        </Table>
        <p>Todo for this page:</p>
        <ul>
          <li>Make it clearer that this only shows completed matches for this tournament</li>
          <li>Maybe show some statistics for the history?</li>
        </ul>
      </>
    )
  }