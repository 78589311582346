import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { useHistory } from "react-router-dom";

import PlayerNames from './PlayerNames';
import PlayerScores from './PlayerScores';
import MatchSchedule from './MatchSchedule';
import Loading from './LoadingIndicator';


// Schedule for all players
// Need to figure out what variables
export default function Schedule(props) {
    const [schedule, setSchedule] = useState([]);
    let history = useHistory();
  
  //  let query = useQuery();
  //  let tournament_id = query.get("tournament_id") || 2;
  
    useEffect( () => {
  
      async function fetchSchedule() {
          const url = props.player ?
            `/api/tournament/4/player/${props.player.id}/schedule`
            : `/api/tournament/4/schedule`
          const response = await axios(url)
          setSchedule(response.data);
      }
  
      fetchSchedule();
    }, [props.player]);       // this empty arrays states no dependent variables should trigger update calls
  
      function gotoMatch(id) { history.push(`match/${id}`) }
      
      if(schedule.length===0) return <Loading />

      return (
        <>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <td>Round</td>
                  <td>Players</td>
                  <td>Result</td>
                  <td>Schedule</td>
                </tr>
              </thead>
              <tbody>
                  {schedule.map( match =>
                  <tr key={match.id} onClick={gotoMatch.bind(this,match.id)}>
                      <td>{match.round}</td>
                      <td><PlayerNames player={props.player} match={match} /></td>
                      <td><PlayerScores player={props.player} match={match} /></td>
                      <td><MatchSchedule match={match} /></td>
                  </tr>
                  )}
              </tbody>
            </Table>
            <p>Todo for this page</p>
            <ul>
              <li>Be able to toggle between complete and personal schedule</li>
              <li>Maybe pagination by round for complete schedule?</li>
              <li>Maybe show completion percentage for complete schedule?</li>
            </ul>
          </>
      )
  }
  