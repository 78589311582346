import React from 'react'

import XWingList from './XWingList'

function PlayerDisplay(props) {
    let player = props.player
    if(!player) return null
    return (<>
        <a href={`/player/${player.id}/history`} style={{ color: 'inherit', textDecoration: 'none' }}>
            {player.isYou ? 'you' : player.display_name}
        </a>
        {player.list && <>&nbsp;<XWingList list={player.list} /></>}
    </>)
}

export default function PlayerNames(props) {
    let match = props.match
    let [ left, right ] = [match.player, match.opponent]
    // TODO: remove because legacy
    if (!left && !right && match.player1 && match.player2) {
        [ left, right ] = [match.player1, match.player2]
        if (match.player2.isYou) [ left, right ] = [ right, left ]
    }
    return (<><PlayerDisplay player={left} /> vs <PlayerDisplay player={right} /></>)
}