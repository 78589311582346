import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';


// Standings for current tournament or ELO-pool
// Need to figure out what variables
export default function Standings() {
    const [standings, setStandings] = useState([]);
    
    //  let query = useQuery();
    //  let tournament_id = query.get("tournament_id") || 2;
  
    useEffect(() => {
  
      async function fetchStandings() {
        const response = await axios(`/api/tournament/4/standings`)
        let rows = response.data
        rows.forEach(row => {
          row.record = { wins: row.wins, losses: row.losses, mov: row.mov }
          row.average = { win_ratio: row.win_ratio, mov: row.average_mov }
        })
        setStandings(response.data);
      }
  
      fetchStandings();
    }, []);       // this empty arrays states no dependent variables should trigger update calls
  
    const columns = [
      { dataField: 'rank', text: '', sort: true, headerStyle: () => { return { width: "4em" } } },
      { dataField: 'player', text: 'Player',
        formatter: (name, standing) => <a href={`/player/${standing.player_id}/history`} style={{ color: 'inherit', textDecoration: 'none' }}>{name}</a>
      },
      { dataField: 'record', text: 'Record',
          style: () => { return { whiteSpace: 'nowrap'} },
          sort: true,
          formatter: rec => `${rec.wins}/${rec.losses} (${rec.mov})`,
          sortFunc: (a, b, order) => {
            if (a.wins !== b.wins) return (order === 'asc') ? (a.wins - b.wins) : (b.wins - a.wins)
            return (order === 'asc') ? (a.mov - b.mov) : (b.mov - a.mov)
          }
      },
      { dataField: 'average', text: 'Win %',
          style: () => { return { whiteSpace: 'nowrap' } },
          sort: true,
          formatter: avg => `${Math.round(100 * avg.win_ratio)}% (${Math.round(avg.mov)})`,
          sortFunc: (a, b, order) => {
            if (a.win_ratio !== b.win_ratio) return (order === 'asc') ? (a.win_ratio - b.win_ratio) : (b.win_ratio - a.win_ratio)
            return (order === 'asc') ? (a.mov - b.mov) : (b.mov - a.mov)
          }
      },
    ];

    return (
      <>
        <BootstrapTable bootstrap4
          striped bordered hover
          classes="table-dark"
          keyField='player_id' data={standings} columns={columns}
          defaultSorted={ [ {dataField: 'rank', order: 'asc'} ] }
        />

        <p>Todo for this page:</p>
        <ul>
          <li>Highlight currently logged in player</li>
          <li>Make players clickable for their tournament history</li>
          <li>Perhaps toggle between total and average standings</li>
        </ul>
      </>
    )
  }
  