import React from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from "react-router-dom";

const LOGIN_TOKEN_COOKIE = "Astromech-Login-Token";

// Removes any set login token
export default function Logout() {
    const [,, removeCookie] = useCookies([LOGIN_TOKEN_COOKIE]);
    removeCookie(LOGIN_TOKEN_COOKIE);
    return (<Redirect to="/" />)
}



