import React from 'react';

export default function Profile(props) {
    return(
      <>
        <h3>Profile</h3>
        <p>
          This page should allow for modifying phone-number/email/profile-pic etcetera.
        </p>
      </>
    )
  }
  