import React, { useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'

export default function ListForm(props) {
    let { match_id, player_id } = useParams()
    let history = useHistory()

    // State and fetcher for the list
    const [list, setList] = useState("");
    useEffect(() => {
        async function fetchList(match_id, player_id) {
            const response = await axios(`/api/match/${match_id}/player/${player_id}/list`)
            const list = response.data
            setList( JSON.stringify(list) );
        }
        fetchList(match_id, player_id);
    }, [match_id, player_id]);

    async function saveList(list) {
        await axios.post(`/api/match/${match_id}/player/${player_id}/list`, {list: list})
        // TODO: handle errors maybe?
        history.goBack();
    }

    if(!list) return null

    // on submit, send the textarea to the server and pop history
    return (
        <Formik
            initialValues={{list: (list==='{}'?'':list)}}
            onSubmit={ (values) => saveList(values.list) }
        >
            <Form>
                <Table variant="dark">

                    <tbody>
                        <tr>
                            <td>Paste your list below (YASB/FFG/XWS)</td>
                        </tr>
                        <tr>
                            <td>Your list is private until the match has started/completed</td>
                        </tr>
                        <tr>
                            <td>
                                <Field as="textarea" name="list" style={{width:"100%"}} rows="6" placeholder="Your list link or XWS" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Button type="submit" variant="outline-light">Save</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form>
        </Formik>
    )
}