import React from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, useLocation } from "react-router-dom";

const LOGIN_TOKEN_COOKIE = "Astromech-Login-Token";

// Helper for easily accessing query parameters
function useQuery() { return new URLSearchParams(useLocation().search) }

// Sets the passed login-token in the cookie
export default function Login() {
    const [, setCookie] = useCookies([LOGIN_TOKEN_COOKIE]);
    let query = useQuery();
    let token = query.get("token");
    setCookie(LOGIN_TOKEN_COOKIE, token, { path: '/', maxAge: 30*24*60*60 });
// TODO: invoke /api/profile to confirm it's a valid token, and logout if not
    return(<Redirect to="/" />)
}